.fadingBackground {
  background: linear-gradient(to bottom, rgb(255 255 255 / 10%), transparent, transparent);

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    margin: -2px;
    border: 2px solid transparent;
    border-radius: inherit;
    background: linear-gradient(to bottom, #06428050, transparent) border-box;
    mask:
      linear-gradient(to right, #fff 0%, #fff 100%) padding-box,
      linear-gradient(to right, #fff 0%, #fff 100%);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
}
