.accordion {
  --height: 0;

  .header::-webkit-details-marker,
  .header::marker {
    display: none;
  }

  .body {
    max-height: 0;
  }

  &:has(.controlInput:checked) .body {
    max-height: var(--height);
  }
}
