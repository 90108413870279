.text {
  // Apply a margin bottom if:
  // (1) element is not the last of its type; or
  // (2) element is the only child (e.g. heading)
  :is(li, p, h1, h2, h3, h4, h5, h6) {
    @apply mb-4 mt-6;

    &:first-child {
      @apply mt-0;
    }

    &:last-of-type {
      @apply mb-0;
    }
  }

  p {
    font-weight: 300;
  }

  blockquote {
    @apply mt-4 border-l-4 pl-4 italic;
  }

  hr {
    @apply my-4 border-primary-aqua;
  }
}
