.halfRight {
  @media (width >= 768px) {
    mask-image: url('/vervio-mask.svg');
    mask-mode: alpha;
    mask-repeat: no-repeat;
    mask-size: max(1200px, 100%), 1200px, cover;
    mask-position: 0 var(--header-height);
    width: 50%;
  }
}
