@layer default;

@layer default {
  .button:global(.light) > svg {
    @apply transition-colors duration-default;
  }

  :is(.button:hover, .button:focus, .button:global(.dark), .button.disabled) > svg {
    @apply text-current;
  }
}
